@import "var.conf";
@import "base.css";
@import "common.css";
/*---------------------------------
Page CSS 2018.05
CSS 担当者名 Document 2018.05
Last Update 2018.05 担当者名
---------------------------------*/

  /*
  トップページ
  -------------------------------------*/
  #Page.pageIndex {
    #Header {
      position: absolute;
      top:0;
      left:0;
      z-index: 99;
      background-image: url(../img/header/logo-top.png);
    }
    #GlobalNav {
      z-index: 100;
      ul {
        li {
          a {
            color: #fff;
            text-shadow:0px 0px 20px #737373;
          }
        }
      }
    }
    #MainImg {
      position: relative;
      background: none;
      overflow: hidden;
      min-width: 1000px;
      margin-bottom: 73px;
      #MVideo {
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(50,156,217,.6);
        }
      }
      video {
        vertical-align: top;
        width: 100%;
      }
      h2 {
        position: absolute;
        left: 30px;
        bottom: 30px;
        font-family: 'Aleo', serif;
        font-size: 50px;
        font-weight: 400;
        line-height: 1.4;
        text-transform:uppercase;
        color: #fff;
        letter-spacing: 0.05em;
        span {
          margin-left: 0px;
          font-size: 30px;
          letter-spacing: 0.05em;
        }
      }
    }
    #Main {
      h3{
        line-height: 1.5;
        letter-spacing: 0.06em;
      }
      #ContBox01 {
        padding-bottom: 54px;
        h3 {
          margin-bottom: 25px;
        }
        p {
          text-align: center;
          color: #666666;
          strong {
            font-size: 24px;
            font-weight: 700;
            color: #004da1;
            letter-spacing: 0.05em;
            line-height: 1.7;
          }
          &:nth-of-type(1) {
            margin-bottom: 25px;
          }
        }
      }
      #ContBox02 {
        position: relative;
        #SliderBox {
          margin:0;
          padding-top: 20px;
          padding-bottom: 10px;
          ul.slider {
            li {
              img{
                width: 100%;
                min-width: 1000px;
              }
            }
          }
          .next-arrow {
            position: absolute;
            top: -21px;
            right: 50%;
            margin-right: -500px;
            width: 40px;
            height: 40px;
            &:hover {
              cursor: pointer;
              cursor: hand;
              opacity: 0.7;
            }
          }
          .prev-arrow {
            position: absolute;
            top: -21px;
            left: 50%;
            margin-left: 419px;
            width: 40px;
            height: 40px;
            z-index: 60;
            &:hover {
              cursor: pointer;
              cursor: hand;
              opacity: 0.7;
            }
          }
          .slick-dots {
            bottom:-30px;
          }
          .slick-dots li {
            margin: 0 1px;
          }
          .slick-dots li button:before {
            opacity: 1;
          }
          .slick-dotted.slick-slider {
            padding-top:0px;
          }
        }
      }
      #ContBox03 {
        margin-bottom: 70px;
        position: relative;
        height: 580px;
        background: url(../img/contents/top/map_wall.jpg) center top no-repeat;
        background-color:#92cded;
        .background-line {
          position: absolute;
          top: 0;
          height: 490px;
          width: 50%;
          border-bottom:#0066CC solid 1px;
        }
        #MapImage {
          position: relative;
          margin:0 auto;
          width: 1000px;
          area {
            border:none;
            outline:none;
          }
          #TextBox {
            position: absolute;
            top: 447px;
            left: 0;
            width: 317px;
            h3 {
              margin-bottom: 5px;
              height: 43px;
              border-bottom: #0066cc solid 1px;
              text-align: left;
              font-size: 32px;
              line-height: 32px;
              letter-spacing: 0.05em;
            }
            p {
              padding-left: 2px;
              font-family: 'Aleo', serif;
              font-weight: 400;
              font-size: 14px;
              text-transform:uppercase;
              color: #004da1;
            }
          }
        }

      }
      #ContBox04 {
        h3 {
          margin-bottom: 34px;
        }
        #ContSubBox01 {
          &:after {
            content: "";
            display: block;
            clear: both;
          }
        }
        table {
          float: left;
          th {
            height: 40px;
            padding-bottom: 2px;
            text-align: center;
            font-weight: bold;
            font-size: 16px;
            letter-spacing: 0.25em;
            vertical-align: bottom;
          }
          td {
            text-align: center;
            font-family: 'Aleo', serif;
            font-weight: 400;
            color: #004da1;
            letter-spacing: 0.05em;
            span {
              font-size: 18px;
            }
          }
          tr {
            &:nth-of-type(3) {
              td {
                font-size: 50px;
                line-height: 1;
                //line-height: 50px;
              }
            }
            &:nth-of-type(4) {
              font-size: 18px;
              line-height: 18px;
              letter-spacing: 0.01em;
            }
          }
          &:nth-of-type(1) {
            width: 150px;
            margin-left: 90px;
          }
          &:nth-of-type(2) {
            margin: 0 70px 0 110px;
            width: 300px;
          }
          &:nth-of-type(3) {
            tr {
              th {
                padding-right: 20px;
              }
              &:nth-of-type(1) {
                td {
                  padding-right: 23px;
                }
              }
              &:nth-of-type(3) {
                td:nth-of-type(1) {
                  width: 110px;
                }
                td:nth-of-type(2) {
                  width: 145px;
                  text-align: left;
                }
              }
            }
          }
        }
        p {
          margin-top: 45px;
          text-align: right;
          font-size: 14px;
          color: #878d98;
        }
      figure {
        min-width: 1000px;
        margin: 23px 0 70px;
        img {
          width: 100%;
        }
      }
      }
      #ContBox05 {
        padding-bottom: 80px;
        h3 {
          margin-bottom: 33px;
        }
        ul {
          width: 100%;
          border-top: #eee solid 1px;
          li {
            padding: 20px 0;
            border-bottom: #eee solid 1px;
            font-size: 18px;
            span {
              display: inline-block;
              width: 130px;
              font-family: 'Aleo', serif;
              font-weight: 400;
              font-size: 16px;
              color: #868c97;
            }
            b {
              display: inline-block;
              width: 92px;
              height: 24px;
              margin-right: 60px;
              background-color: #004da1;
              text-align: center;
              font-size: 14px;
              line-height: 24px;
              color: #fff;
            }
            a {
              color: #000;
              text-decoration: none;
              &:after {
                content:url(../img/contents/icon_01.png);
                margin-left: 20px;
              }
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
        .linkBtn {
          margin-top: 40px;
          text-align: center;
          a {
            display:inline-block;
            width: 200px;
            height: 50px;
            border: #007ac1 solid 1px;
            line-height: 48px;
            text-decoration: none;
            color: #007ac1;
            &:hover {
              opacity: 0.7;
            }
          }
        }
      }
    }
  }

body#Page.fixed #Header {
  position: fixed;
  width: 100%;
  z-index: 980;
  height: 70px;
  background-image: none;
  background-color: #fff;
  box-shadow:0px 0px 4px 1px #e8e8e8;
  h1 {
    top: 22px;
    z-index: 999;
    a {
      background-image: url(../img/header/logo-fixed.png);
      width: 294px;
      height: 24px;
      background-size: 294px auto;
    }
  }
}

body#Page.fixed #GlobalNav {
    position: absolute;
    z-index: 990;
    top: 22px;
    right:30px;
    width: 100%;
    ul {
      text-align: right;
      font-size: 0;
      li {
        margin-left:35px ;
        display: inline-block;
        font-size: 16px;
        a {
          color: #000;
          text-decoration: none;
          text-shadow:none;
          &:hover {
            opacity: 0.7;
          }
        }
        &:nth-of-type(1) {
          margin-left: 0;
        }
      }
    }
  }


  /*
  海外工業団地一覧
  -------------------------------------*/
  #PageIndustrial_park {
    #MainImg {
        height: 300px;
        background-image: url(../img/contents/industrial-park/mainimage.jpg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        h2 {
          span {
            padding-left: 6px;
            letter-spacing: 0.1em;
          }
        }
    }
    #Main {
        .mapArea {
            p.gmap {
              margin-bottom: 35px;
              text-align: right;
              a {
                font-size: 14px;
                text-decoration: none;
                color: #007ac1;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
        }
    }
  }

  /*
  海外工業団地一覧top
  -------------------------------------*/
  #PageIndustrial_park.pageIndex {
    #Main {
      padding-top: 115px;
      .contBox {
        padding-top: 100px;
        border-top: #efefef solid 1px;
        .country {
          text-align: center;
          h3 {
            margin-top: 10px;
            line-height: 1.55;
          }
        }
        .mapArea {
          margin-top: -7px;
          .iframeBox {
            position: relative;
            z-index: 1;
            min-height: 100%;
            height: 100%;
            &:before {
              content: "";
              position: absolute;
              top:0;
              left: 0;
              display: block;
              width: 100%;
              height: 46px;
              background-color: #fff;
            }
            iframe {
              width: 1000px;
              height: 460px;
            }
          }
        }
        .contSubBox {
          h4 {
            margin-bottom: 30px;
            padding-bottom: 7px;
            border-bottom: #e1e6e9 solid 2px;
            font-size: 24px;
            letter-spacing: 0.05em;
            a {
              text-decoration: none;
              color: #007ac1;
              &:hover {
                text-decoration: underline;
              }
            }
          }
          img {
            float: left;
            width: 300px;
            height: auto;
            margin-bottom: 100px;
          }
          .detailsBox {
            float: right;
            margin-top: -15px;
          }
          table {
            margin-bottom: 20px;
            width: 660px;
            th {
              padding: 11px 20px 11px 3px;
              width: 150px;
              background: url(../img/contents/line_dot.png) left bottom repeat-x;
              font-size: 16px;
              font-weight: normal;
            }
            td {
              padding: 11px 3px 10px 0;
              background: url(../img/contents/line_dot.png) left bottom repeat-x;
              font-size: 18px;
              line-height: 1.6;
              p {
                font-size: 18px;
                margin-bottom: 8px;
              }
              a {
                text-decoration: none;
                color: #007ac1;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
          .linkBtn {
            clear: both;
            margin-bottom: 74px;
            text-align: right;
            font-size: 0;
            li {
              display: inline-block;
              margin-left: 7px;
              a {
                display: block;
                width: 200px;
                height: 50px;
                border: #007ac1 solid 1px;
                text-align: center;
                text-decoration: none;
                font-size: 16px;
                color: #007ac1;
                line-height: 48px;
                &:hover {
                  opacity: 0.7;
                }
              }
            }
          }
          &:after {
            content: "";
            display: block;
            clear: both;
          }
        }
      }
    }
  }

  /*
  海外工業団地一覧詳細
  -------------------------------------*/
  #PageIndustrial_park.pageEntry {
    #Main {
        .contBox {
          h4 {
            margin-bottom: 30px;
            padding-bottom: 14px;
            border-bottom: #e1e6e9 solid 2px;
            font-size: 32px;
            line-height: 2;
            font-weight: bold;
            line-height: 1.4;
            letter-spacing: 0.05em;
            a {
              text-decoration: none;
              color: #007ac1;
              &:hover {
                text-decoration: underline;
              }
            }
          }
          &:after {
            content: "";
            display: block;
            clear: both;
          }
        }
        .country {
          padding-top: 120px;
          img {
            float: left;
            width: 30px;
            height: auto;
            margin-right: 13px;
          }
          h3 {
            margin-bottom: 25px;
            font-size: 20px;
            text-align: left;
            line-height: 20px;
            span {
              display: inline-block;
              font-size: 12px;
              line-height: 20px;
              vertical-align: top;
            }
          }
        }
        .contSubBox {
          img {
            width: 300px;
            height: auto;
            float: left;
            margin-right:40px;
          }
          .detailsBox {
            float: right;
            margin-top: -15px;
          }
          table {
            margin-bottom: 19px;
            width: 660px;
            th {
              padding: 11px 20px 11px 3px;
              width: 150px;
              background: url(../img/contents/line_dot.png) left bottom repeat-x;
              font-size: 16px;
              font-weight: normal;
            }
            td {
              padding: 11px 3px 10px 0;
              background: url(../img/contents/line_dot.png) left bottom repeat-x;
              font-size: 18px;
              line-height: 1.6;
              a {
                text-decoration: none;
                color: #007ac1;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
          &:after {
            content: "";
            display: block;
            clear: both;
          }
        }
        .mapArea {
          margin-top: 40px;
            .iframeBox {
            position: relative;
            z-index: 1;
            min-height: 100%;
            height: 100%;
            iframe {
              width: 1000px;
              height: 460px;
            }
          }
        }
        .linkBtn {
          clear: both;
            text-align: right;
            font-size: 0;
            li {
              display: inline-block;
              margin-left: 7px;
              a {
                display: block;
                width: 200px;
                height: 50px;
                border: #007ac1 solid 1px;
                text-align: center;
                text-decoration: none;
                font-size: 16px;
                color: #007ac1;
                line-height: 48px;
                &:hover {
                  opacity: 0.7;
                }
              }
            }
          }
        #Message {
          margin-top: 45px;
          padding: 50px 40px 40px;
          background-color: #f5f7fa;
          h5 {
            margin-bottom: 30px;
            padding-left: 15px;
            border-left: #004da1 solid 5px;
            font-size: 18px;
            font-weight: bold;
            line-height: 26px;
            color: #004da1;
          }
          p {
            font-size: 18px;
          }
        }
        #BlogLink {
          margin-top: 100px;
          padding-top: 40px;
          margin-bottom: 217px;
          border-top: #efefef solid 1px;
          .linkBtn {
            text-align: center;
          }
        }

    }
  }

  /*
  資料ダウンロード
  -------------------------------------*/
  #PagePamphlet.pageIndex {
    #MainImg {
      height: 300px;
        background-image: url(../img/contents/pamphlet/mainimage.jpg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        h2 {
          span {
            padding-left: 6px;
            letter-spacing: 0.1em;
          }
        }
      }
    #Main {
      ul.pageLink {
        margin-bottom: 74px;
        li {
          margin-right: 37px;
          &:nth-of-type(8) {
            margin-right: 0;
          }
        }
        &:last-child {
          padding-top: 0;
        }
      }
      padding-top: 115px;
      .contBox {
        padding-top: 100px;
        border-top: #efefef solid 1px;
        .country {
          float: left;
          margin-bottom: 100px;
          padding-top: 95px;
          width: 300px;
          height: 300px;
          background-color: #f6f7f7;
          border:#efefef solid 1px;
          text-align: center;
          h3 {
            margin-top: 10px;
            line-height: 1.5;
          }
        }
        .listBox {
          float: right;
          margin-bottom: 40px;
          width: 630px;
          dl {
            margin-top: -6px;
            margin-bottom: 63px;
            dt {
              margin-bottom: 13px;
              padding-bottom: 12px;
              border-bottom: #e1e6e9 solid 2px;
              font-size: 24px;
              line-height: 1.45;
              letter-spacing: 0.05em;
              a {
                position: relative;
                display: inline-block;
                padding-left: 20px;
                text-decoration: none;
                &:before {
                  position: absolute;
                  top:0;
                  left:0;
                  content: url(../img/contents/pamphlet/arrow.png);
                }
                &:hover {
                  text-decoration: underline;
                }
              }
            }
            dd {
              padding: 15px 0 16px;
              background: url(../img/contents/line_dot.png) left bottom repeat-x;
              font-size: 18px;
              line-height: 1.4;
              a {
                text-decoration: none;
                color: #007ac1;
                &:hover {
                  text-decoration: underline;
                }
              }
              &.pdf {
                a {
                  position: relative;
                  display: inline-block;
                  padding-left: 35px;
                  &:before {
                    position: absolute;
                    top:2px;
                    left: 0;
                    content: url(../img/contents/pamphlet/icon_pdf.png);
                  }
                }
              }
              &.word {
                a {
                  position: relative;
                  display: inline-block;
                  padding-left: 35px;
                  &:before {
                    position: absolute;
                    top:2px;
                    left: 0;
                    content: url(../img/contents/pamphlet/icon_word.png);
                  }
                }
              }
              &.excel {
                a {
                  position: relative;
                  display: inline-block;
                  padding-left: 35px;
                  &:before {
                    position: absolute;
                    top:2px;
                    left: 0;
                    content: url(../img/contents/pamphlet/icon_excel.png);
                  }
                }
              }
            }
          }
        }
      }
      #ContBox01 {
        .country {
          padding-top: 111px;
          h3 {
            display: none;
          }
        }
      }
      #ContBox09.contBox {
        padding-top: 75px;
        margin-bottom: 200px;
      }
    }
  }

  /*
  PageTopics
  -------------------------------------*/
  #PageTopics {
    #MainImg {
      height: 300px;
        background-image: url(../img/contents/topics/mainimage.jpg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        h2 {
          margin-left: -4px;
          span {
            padding-left: 7px;
          }
        }
    }
    #Main {
        .linkBtn {
          margin-top: 50px;
          text-align: center;
          a {
            display:inline-block;
            width: 200px;
            height: 50px;
            border: #007ac1 solid 1px;
            line-height: 48px;
            text-decoration: none;
            color: #007ac1;
            &:hover {
              opacity: 0.7;
            }
          }
        }
    }
  }

  #PageTopics.pageIndex {
    #Main {
      padding-top: 120px;
      #ContBox01 {
        padding-bottom: 200px;
        ul {
          width: 100%;
          border-top: #eee solid 1px;
          li {
            padding: 29px 0 31px;
            border-bottom: #eee solid 1px;
            font-size: 18px;
            span {
              display: inline-block;
              width: 130px;
              font-family: 'Aleo', serif;
              font-weight: 400;
              font-size: 16px;
              color: #868c97;
            }
            b {
              display: inline-block;
              width: 92px;
              height: 24px;
              margin-right: 60px;
              background-color: #004da1;
              text-align: center;
              font-size: 14px;
              line-height: 24px;
              color: #fff;
            }
            a {
              color: #000;
              text-decoration: none;
              &:after {
                content:url(../img/contents/icon_01.png);
                margin-left: 20px;
              }
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }

  /*
  記事詳細ページ
  -------------------------------------*/
  #PageTopics.pageEntry {
    #Main {
      padding-top: 101px;
      padding-bottom: 200px;
      h3 {
        padding-bottom: 12px;
        margin-bottom: 36px;
        border-bottom: #e1e6e9 solid 2px;
        text-align: left;
        line-height: 1.4;
        letter-spacing: 0.05em;
            span {
              display: inline-block;
              margin-bottom: 23px;
              width: 106px;
              font-size: 16px;
              color: #868c97;
            }
            b {
              display: inline-block;
              width: 92px;
              height: 24px;
              margin-right: 10px;
              background-color: #004da1;
              text-align: center;
              font-size: 14px;
              line-height: 24px;
              font-weight: normal;
              color: #fff;
            }
      }
      .entryBox {
        padding-bottom: 75px;
        border-bottom: #eee solid 1px;
      }
      .linkBtn {
        margin-top: 40px;
      }
    }
  }



  /*
  お問い合わせ共通
  -------------------------------------*/
  #PageContact {
    #MainImg {
      height: 300px;
        background-image: url(../img/contents/contact/mainimage.jpg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        h2 {
          margin-left: -2px;
          span {
            padding-left: 6px;
            letter-spacing: 0.1em;
          }
        }
    }
    #Main {
      padding-top: 120px;
      margin-bottom: 250px;
      table {
        border-top: #eeeeee solid 1px;
        width: 1000px;
        margin-bottom: 60px;
        th {
          position: relative;
          width: 320px;
          padding: 32px 0 31px;
          border-bottom: #eeeeee solid 1px;
          font-size: 16px;
          font-weight: normal;
          vertical-align: top;
          span {
            display: block;
            position: absolute;
            top: 36px;
            right: 68px;
            padding: 4px 5px;
            background-color: #d53a5f;
            font-size: 12px;
            line-height: 12px;
            font-weight: normal;
            color: #fff;
          }
          &.any {
            span {
              background-color: #a6a9ab;
            }
          }
        }
        td {
          padding: 19px 0 18px;
          text-align: left;
          font-size: 18px;
          border-bottom: #eeeeee solid 1px;
        }
        tr {
          &:nth-of-type(8) {
            td {
              padding-top: 28px;
              padding-bottom: 30px;
              li {
                display: inline-block;
                margin-bottom: 20px;
                width: 153px;
                &:nth-of-type(5),
                &:nth-of-type(6),
                &:nth-of-type(7) {
                  margin-bottom: 0;
                }
              }
            }
          }
          &:nth-of-type(9) {
            td {
              padding-top: 30px;
              label {
                margin-right: 35px;
              }
            }
          }
          &:nth-of-type(10) {
            td {
              padding-bottom: 13px;
              li {
                margin-bottom: 18px;
                &:nth-of-type(2) {
                  margin-bottom: 30px;
                }
              }
            }
          }
           &:nth-of-type(11) {
            td {
              padding-bottom: 10px;
              textarea {
                margin-bottom:8px;
              }
            }
           }
          &:nth-of-type(12) {
            td {
              padding-top: 28px;
              padding-bottom: 31px;
              li {
              padding-left: 1.5em;
              text-indent: -1.5em;
              &:before {
                content: "※ ";
              }
              a {
                color: #007ac1;
                &:after {
                  content: url(../img/contents/contact/icon_link.png);
                }
              }
            }
            }
          }
        }
      }
    }
    form {
      input[type="text"],
      input[type="email"],
      input[type="tel"],
      textarea {
        padding: 11px 20px 12px 20px;
        background-color: #f3f3f3;
        -webkit-appearance: none !important;
        border-radius: 0;
      }
      input.txt01 {
        width: 450px;
      }
      input.txt02 {
        width: 260px;
      }
      input.txt03 {
        width: 130px;
      }
      textarea {
        width: 680px;
        height: 266px;
      }
      .radio span input {
        display: block;
        width: 30px !important;
        height: 30px !important;
        border-radius: 50% !important;
      }
      input::placeholder,
      input:-ms-input-placeholder {
        color:#8e8e8e;
      }
      input:focus,
      textarea:focus {
        outline: 2px #3585dd solid;
        background-color: #fff;
      }
      div.checker span {
        width:30px;
        height:30px;
        background:url(../img/contents/contact/icon_check02.jpg) no-repeat center center;
      }
      div.checker span.checked {
        background:url(../img/contents/contact/icon_check01.jpg) no-repeat center center;
      }
      div.radio {
        height: 30px;
      }
      div.radio span {
        margin-top: -1px;
        width:30px;
        height:30px;
        background:url(../img/contents/contact/icon_radio02.jpg) no-repeat center center;
      }
      div.radio span.checked {
        background:url(../img/contents/contact/icon_radio01.jpg) no-repeat center center;
      }
      .btnBox {
        .submitBtn {
          text-align: center;
          input {
            display:inline-block;
            width: 240px;
            height: 60px;
            background-color:#007ac1;
            font-size: 20px;
            color: #ffffff;
            line-height: 60px;
            border-radius: 0;
            &:hover {
              opacity: 0.7;
            }
          }
        }
      }
    }
  }
  /*
  お問い合わせtop
  -------------------------------------*/
  #PageContact.pageIndex {
    #Main {
      .errs {
        margin-bottom: 50px;
        ul {
          margin: 0 auto;
          width: 500px;
          li {
            width: 500px;
            text-align: center;
            color: red;
          }
        }
      }
    }
    input[type="text"].err,
    input[type="email"].err,
    input[type="tel"].err,
    textarea.err {
      background-color: #fbecec;
    }
  }

  /*
  確認画面
  -------------------------------------*/
  #PageContact.pageConfirm {
    #Main {
      padding-top: 110px;
      margin-bottom: 285px;
      h3 {
        margin-bottom: 18px;
      }
      p {
        text-align: center;
        font-size: 18px;
      }
      table {
        margin-top: 75px;
        td {
          padding-top: 28px;
        }
        tr {
          &:nth-of-type(10),
          &:nth-of-type(11) {
            td {
              padding-bottom: 28px;
            }
          }
          &:nth-of-type(11) {
            td {
              padding-top: 28px;
              padding-bottom: 32px;
              li {
                padding-left: 1.5em;
                text-indent: -1.5em;
                &:before {
                  content: "※ ";
                }
            a {
              color: #007ac1;
              &:after {
                content: url(../img/contents/contact/icon_link.png);
              }
            }
              }
            }
          }
        }
      }
      .btnBox {
        position: relative;
        p.btn {
          position: absolute;
          top: 19px;
          left: 0;
          font-size: 16px;
          a {
            color: #007ac1;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  /*
  送信完了画面
  -------------------------------------*/
  #PageContact.pageThanks {
    #Main {
      padding-top: 110px;
      margin-bottom: 60px;
      h3 {
        margin-bottom: 18px;
      }
      p {
        text-align: center;
        font-size: 18px;
        &.btn {
          margin-top: 55px;
          a {
            display: inline-block;
            width: 240px;
            height: 60px;
            background-color: #eaeaea;
            font-size: 20px;
            color: #7e7e7e;
            line-height: 60px;
            text-decoration: none;
            &:hover {
              opacity: 0.7;
            }
          }
        }
      }
    }
  }

/*
  404ページ
  -------------------------------------*/
  #Page404 {
    min-height: 1200px;
    #Main {
      padding: 300px 0;
      #ContBox01 {
        text-align: center;
        p {
          text-align: center;
        }
        .linkBtn {
          margin-top: 40px;
          a {
            display: inline-block;
            width: 240px;
            height: 60px;
            background-color:  #007ac1;
            line-height: 60px;
            font-size: 20px;
            color: #ffffff;
            text-decoration: none;
            &:hover {
              opacity: 0.7;
            }
          }
        }
      }
    }
  }



@media screen and (max-width: 1280px) {
   #Page.pageIndex {
    #MainImg video{
      width: 1280px;
      height: 550px;
    }
  }
}



@media all and (-ms-high-contrast:none){
  *::-ms-backdrop, .background-line { height:490px; }
  *::-ms-backdrop, #Page.pageIndex #Main #ContBox03 #MapImage #TextBox { width: 320px; }
  *::-ms-backdrop, #Page.pageIndex #Main #ContBox05 ul li b { padding-top: 3px; }
  *::-ms-backdrop, .linkBtn a { padding-top: 3px; }
  *::-ms-backdrop, #Main ul.pageLink li a { background-position: left 4px; }
  *::-ms-backdrop, #Main ul.pageLink:last-child li a { background-position: left 5px; }
  *::-ms-backdrop, #PagePamphlet.pageIndex #Main .contBox .listBox dl dd.pdf a:before { top:-2px; }
  *::-ms-backdrop, #PagePamphlet.pageIndex #Main .contBox .listBox dl dd.word a:before { top:-2px; }
  *::-ms-backdrop, #PagePamphlet.pageIndex #Main .contBox .listBox dl dd.excel a:before { top:-2px; }
  *::-ms-backdrop, #PageTopics.pageIndex #Main #ContBox01 ul li b { padding-top: 3px; }
  *::-ms-backdrop, #PageTopics.pageEntry #Main h3 b { padding-top: 3px; }
  *::-ms-backdrop, #PageContact #Main table tr:nth-of-type(11) td { padding-bottom: 8px; }
  *::-ms-backdrop, #PageContact #Main table th { padding-top: 40px;}
  *::-ms-backdrop, #PageContact form input[type="text"] { padding-top: 15px; padding-bottom: 10px;}
  *::-ms-backdrop, #PageContact form input[type="email"] { padding-top: 15px; padding-bottom: 10px;}
  *::-ms-backdrop, #PageContact form input[type="tel"] { padding-top: 15px; padding-bottom: 10px;}
  *::-ms-backdrop, #PageContact form textarea { padding-top: 15px; padding-bottom: 10px;}
  *::-ms-backdrop, #PageContact #Main table th span { padding-top: 6px; padding-bottom: 2px;}
  /* IE11 */
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
  #PageContact #Main table tr:nth-of-type(11) td { padding-bottom: 0px; }
/*  #Page.pageIndex #Main #ContBox02 #SliderBox .slick-dots li button:before {font-size: 24px;}
  #Page.pageIndex #Main #ContBox02 #SliderBox .slick-dots li.slick-active button:before {font-size: 24px;}*/
   /* Chrome */
}
@-moz-document url-prefix() {
  #PageContact #Main table tr:nth-of-type(11) td textarea { margin-bottom: 20px; }
   /* firefox */
}
@supports (-ms-ime-align: auto) {
  #PageContact #Main table tr:nth-of-type(11) td textarea { margin-bottom: 20px; }
/*  #Page.pageIndex #Main #ContBox02 #SliderBox .slick-dots li button:before {font-size: 36px;}
  #Page.pageIndex #Main #ContBox02 #SliderBox .slick-dots li.slick-active button:before {font-size: 36px;}*/
  #PageIndustrial_park.pageIndex #Main .contBox .mapArea { margin-top: -5px;}
   /* Edge */
}
/* end */